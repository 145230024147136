import React, { useState } from "react";
import { Amplify } from 'aws-amplify';
import { signUp } from '@aws-amplify/auth';
import { useNavigate, Link } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import "./Login.css";
import  "./Signup.css";
import useSubmitButton from '../../hooks/useSubmitButton';
import { getErrorMessage } from '../../utils/errorMessages';
import awsconfig from '../../aws-exports';

// Configure Amplify
Amplify.configure(awsconfig);

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const submitAction = async () => {
    if (password !== confirmPassword) {
      throw new Error(getErrorMessage('PASSWORD_MISMATCH'));
    }

    if (!validatePassword(password)) {
      throw new Error(getErrorMessage('INVALID_PASSWORD'));
    }

    try {
      await signUp({
        username: email,
        password: password,
        attributes: {
          email: email
        }
      });
      console.log("サインアップ成功");
      navigate('/confirm-signup', { state: { email, password } });
    } catch (error) {
      console.error('サインアップエラー', error);
      throw new Error(getErrorMessage(error));
    }
  };

  const { isLoading, error, handleSubmit } = useSubmitButton(submitAction);

  return (
    <div className={`login-page ${isLoading ? 'loading' : ''}`}>
      <div className="login_form">
        <form onSubmit={handleSubmit}>
          <h2>友達さん会員登録</h2>

          <div className="input_box">
            <label htmlFor="email">メールアドレス(必須)</label>
            <input
              type="email"
              id="email"
              placeholder="メールアドレスを入力してください"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="input_box">
            <div className="password_title">
              <label htmlFor="password">パスワード(必須)</label>
            </div>
            <div className="password-input-eye-container">
                <div className="passwordinput">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    placeholder="パスワードを入力してください"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="showpassword">
                  {showPassword ? (
                    <FaEyeSlash onClick={togglePasswordVisibility} />
                  ) : (
                    <FaEye onClick={togglePasswordVisibility} />
                  )}
                </div>

            </div>
          </div>

          <div className="input_box">
            <div className="password_title">
              <label htmlFor="confirmPassword">パスワード再入力(必須)</label>
            </div>
            <input
              type="password"
              id="confirmPassword"
              placeholder="パスワードをもう一度入力してください"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          {error && <div className="error-msg">{error}</div>}
          <button type="submit" disabled={isLoading}>
            {isLoading ? '処理中...' : 'アカウントを作成'}
          </button>
          <p className="sign_up">
            会員登録済みですか？ <Link to="/login">ログイン</Link>
          </p>
        </form>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <ClipLoader className="spinner" loading={isLoading} />
        </div>
      )}
    </div>
  );
}

export default Signup;
