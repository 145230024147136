const errorMessages = {
    // General errors
    DEFAULT: "エラーが発生しました。もう一度お試しください。",
    NETWORK_ERROR: "ネットワークエラーが発生しました。インターネット接続を確認してください。",
  
    // Sign up errors
    USERNAME_EXISTS: "このメールアドレスは既に登録されています。ログインしてください。",
    INVALID_PASSWORD: "パスワードは6文字以上である必要があります。",
    PASSWORD_MISMATCH: "パスワードが一致しません。",
  
    // Sign in errors
    USER_NOT_FOUND: "ユーザーが見つかりません。ユーザーを登録してください。",
    INCORRECT_PASSWORD: "メールアドレスまたはパスワードが間違っています。",
    USER_NOT_CONFIRMED: "アカウントが確認されていません。確認コードを入力してください。",
  
    // Confirmation errors
    EXPIRED_CODE: "確認コードの有効期限が切れています。新しいコードを要求してください。",
    INVALID_CODE: "無効な確認コードです。もう一度お試しください。",
    CODE_MISMATCH: "確認コードが一致しません。もう一度ご確認ください。",
    TOO_MANY_ATTEMPTS: "確認の試行回数が多すぎます。しばらくしてからもう一度お試しください。",
    USER_CONFIRMED: "このアカウントは既に確認済みです。ログインしてください。",
    INVALID_EMAIL: "有効なメールアドレスが見つかりません。サインアップ処理をやり直してください。",
  
    // Password reset errors
    INVALID_RESET_CODE: "無効なパスワードリセットコードです。もう一度お試しください。",
    PASSWORD_RESET_REQUIRED: "パスワードのリセットが必要です。メールをご確認ください。",
  
    // Limit exceeded errors
    ATTEMPT_LIMIT: "試行回数が多すぎます。しばらくしてからもう一度お試しください。",
  
    // Other specific errors
    WEAK_PASSWORD: "パスワードが弱すぎます。より強力なパスワードを選択してください。",
    INVALID_PARAMETER: "入力された情報が正しくありません。もう一度確認してください。",
    NOT_AUTHORIZED: "認証に失敗しました。パスワードを確認してください。",
    LIMIT_EXCEEDED: "試行回数が多すぎます。しばらくしてからもう一度お試しください。",
    INVALID_SESSION: "セッションが無効です。もう一度ログインしてください。",
    ADDITIONAL_VERIFICATION_REQUIRED: "追加の認証ステップが必要です。",
    UNEXPECTED_SIGN_IN_RESPONSE: "予期せぬログイン応答がありました。もう一度お試しください。",
  
    // Custom errors
    REASON_REQUIRED: "少なくとも1つの理由を選択してください。",
};

export const getErrorMessage = (error) => {
    if (typeof error === 'string') {
        return errorMessages[error] || errorMessages.DEFAULT;
    }

    // Handle Amplify specific errors
    if (error.code) {
        return errorMessages[error.code] || errorMessages.DEFAULT;
    }

    // Handle Amplify specific errors
    switch (error.name) {
        case 'InvalidParameterException':
            return errorMessages.INVALID_PARAMETER;
        case 'UserNotFoundException':
            return errorMessages.USER_NOT_FOUND;
        case 'NotAuthorizedException':
            return errorMessages.NOT_AUTHORIZED;
        case 'CodeMismatchException':
            return errorMessages.CODE_MISMATCH;
        case 'ExpiredCodeException':
            return errorMessages.EXPIRED_CODE;
        case 'LimitExceededException':
            return errorMessages.LIMIT_EXCEEDED;
        case 'InvalidSessionException':
            return errorMessages.INVALID_SESSION;
        case 'UsernameExistsException':
            return errorMessages.USERNAME_EXISTS;
        case 'UserNotConfirmedException':
            return errorMessages.USER_NOT_CONFIRMED;
        case 'TooManyRequestsException':
            return errorMessages.ATTEMPT_LIMIT;
        case 'InvalidPasswordException':
            return errorMessages.WEAK_PASSWORD;
        case 'CodeDeliveryFailureException':
            return errorMessages.NETWORK_ERROR;
        case 'InvalidEmailRoleAccessPolicyException':
            return errorMessages.INVALID_EMAIL;
        case 'PasswordResetRequiredException':
            return errorMessages.PASSWORD_RESET_REQUIRED;
        default:
            return error.message || errorMessages.DEFAULT;
    }
};
