import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { ClipLoader } from "react-spinners";
import axios from 'axios';
import "./Login.css";
import "./InitialUserInfo.css";
import awsconfig from '../../aws-exports';
import useSubmitButton from '../../hooks/useSubmitButton';
import { getErrorMessage } from '../../utils/errorMessages';
import { Navigate } from 'react-router-dom';

// Configure Amplify with AWS settings
Amplify.configure(awsconfig);
const apiDomain = awsconfig.aws_cloud_logic_custom[0].endpoint;
// console.log("apiDomain",apiDomain)

const checkLoginStatus = async () => {
  // setIsLoading(true); // Set loading to true when starting the fetch
  try {
    const user = await fetchAuthSession();
    // console.log("ユーザー情報:", user);
    if (user) {
      // setLoginStatus(true);
      // setShowPopup(false);

      // Extract email from the token payload
      const email = user.tokens.idToken.payload.email;

      // Call API to get user info with email
      const response = await fetch(
        `${apiDomain}/userinfo`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.tokens.idToken}`, // Ensure the token is correct
          },
        }
      );
      const userInfo = await response.json();
      // console.log("User Info from API:", userInfo);

      // Check if user_exists is true or false
      // console.log("userInfo.user.user_exists:", userInfo.user_exists);
      if (userInfo.user_exists === false) {
        return;
      }else{
        Navigate('/');
      }
    } else {
      Navigate('/');
    }
  } catch (error) {
    Navigate('/');
  } 
};



// Mapping object
const reasonMapping = {
  'ベトナム語会話力を練習したい': 1,
  '日本語会話力を練習したい': 2,
  '異文化交流を楽しみたい': 3,
  '気軽いにいろいろな人と話したい': 4
};

function InitialUserInfo() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [nationality, setNationality] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastNameKana, setLastNameKana] = useState('');
  const [firstNameKana, setFirstNameKana] = useState('');
  const [gender, setGender] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [checkboxError, setCheckboxError] = useState('');

  // Generate an array of years from 1900 to the current year
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1899 }, (_, i) => currentYear - i);

  // Japanese-specific fields
  const [vietnameseStudyPeriod, setVietnameseStudyPeriod] = useState('');
  const [vietnameseSpeakingAbility, setVietnameseSpeakingAbility] = useState('');
  const [vietnamLivingPeriod, setVietnamLivingPeriod] = useState('');
  const [japaneseReasons, setJapaneseReasons] = useState([]);

  // Vietnamese-specific fields
  const [japaneseStudyPeriod, setJapaneseStudyPeriod] = useState('');
  const [japaneseQualification, setJapaneseQualification] = useState('');
  const [japaneseSpeakingAbility, setJapaneseSpeakingAbility] = useState('');
  const [japanLivingPeriod, setJapanLivingPeriod] = useState('');
  const [vietnameseReasons, setVietnameseReasons] = useState([]);

  // Check authentication status on component mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const user = await getCurrentUser();
        // console.log("user", user);
        if (user) {
          setIsAuthenticated(true);
          const session = await fetchAuthSession();
          const token = session.tokens.idToken; // Ensure this is the correct path to the JWT token

          // Directly access the email from the token payload
          const email = token.payload.email; // Extract email from the token payload
          setUserEmail(email); // Set the email state
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        navigate('/login');
      }
    };

    checkAuthStatus();
  }, [navigate]);

  // Move the useEffect for checkLoginStatus here
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const user = await fetchAuthSession();
        // console.log("ユーザー情報:", user);
        if (user) {
          const email = user.tokens.idToken.payload.email;
          const response = await fetch(
            `${apiDomain}/userinfo`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${user.tokens.idToken}`,
              },
            }
          );
          const userInfo = await response.json();
          // console.log("User Info from API:", userInfo);

          if (userInfo.user_exists === false) {
            return;
          } else {
            navigate('/');
          }
        } else {
          navigate('/');
        }
      } catch (error) {
        navigate('/');
      }
    };

    checkLoginStatus();
  }, [navigate]);

  // Gender options mapped to integers
  const genderOptions = {
    male: 1,
    female: 2,
  };

  // Mapping function for converting string values to integers
  const mapToInteger = (value) => {
    const mapping = {
      'なし': 0,
      '<1年': 1,
      '1年〜3年': 2,
      '3年〜5年': 3,
      '5年〜10年': 4,
      '>10年': 5,
      '初級': 1,
      '中級': 2,
      '中上級':3,
      '上級': 4,
      '熟練者': 5,
      'N5': 1,
      'N4': 2,
      'N3': 3,
      'N2': 4,
      'N1': 5
    };
    return mapping[value] || 0; // Default to 0 if not found
  };

  // Function to handle form submission
  const submitAction = async () => {
    if (!isAuthenticated) {
      throw new Error('User is not authenticated');
    }

    // Collect user attributes based on form inputs
    const userAttributes = {
      ...(nationality && { 'custom:nationality': nationality }),
      ...(lastName && { 'custom:lastName': lastName }),
      ...(firstName && { 'custom:firstName': firstName }),
      ...(lastNameKana && { 'custom:lastNameKana': lastNameKana }),
      ...(firstNameKana && { 'custom:firstNameKana': firstNameKana }),
      ...(gender && { 'custom:gender': genderOptions[gender] }), // Map gender to integer
      ...(birthYear && { 'custom:birthYear': birthYear }),
      ...(nationality === 'Japan' && {
        ...(vietnameseStudyPeriod && { 'custom:vietnameseStudyPeriod': mapToInteger(vietnameseStudyPeriod) }),
        ...(vietnameseSpeakingAbility && { 'custom:vietnameseSpeakingAbility': mapToInteger(vietnameseSpeakingAbility) }),
        ...(vietnamLivingPeriod && { 'custom:vietnamLivingPeriod': mapToInteger(vietnamLivingPeriod) }),
        ...(japaneseReasons.length > 0 && { 
          'custom:japaneseReasons': japaneseReasons.map(reason => reasonMapping[reason]).join(',')
        }),
      }),
      ...((nationality === 'Vietnam' || nationality === 'Other') && {
        ...(japaneseStudyPeriod && { 'custom:japaneseStudyPeriod': mapToInteger(japaneseStudyPeriod) }),
        ...(japaneseQualification && { 'custom:japaneseQualification': mapToInteger(japaneseQualification) }),
        ...(japaneseSpeakingAbility && { 'custom:japaneseSpeakingAbility': mapToInteger(japaneseSpeakingAbility) }),
        ...(japanLivingPeriod && { 'custom:japanLivingPeriod': mapToInteger(japanLivingPeriod) }),
        ...(vietnameseReasons.length > 0 && {
          'custom:vietnameseReasons': vietnameseReasons.map(reason => reasonMapping[reason]).join(',')
        }),
      }),
    };

    try {
      // Fetch authentication session and token
      const session = await fetchAuthSession();
      const token = session.tokens.idToken; // Ensure this is the correct path to the JWT token
      // console.log("token", token);

      // Send user attributes to the server
      const response = await axios.post(`${apiDomain}/userinfo`, {
        ...userAttributes
      },
      { 
        headers: {
          'Authorization': `Bearer ${token}` // Ensure the format is correct
        }
      });
      // console.log(response);
      navigate('/');
    } catch (error) {
      console.error('Error in submitAction:', error);
      throw new Error(getErrorMessage(error));
    }
  };

  // Custom hook to handle form submission state
  const { isLoading, error, handleSubmit } = useSubmitButton(async () => {
    // Validate that at least one checkbox is selected
    if (nationality === 'Japan' && japaneseReasons.length === 0) {
      setCheckboxError(getErrorMessage('REASON_REQUIRED'));
      return;
    }
    if ((nationality === 'Vietnam' || nationality === 'Other') && vietnameseReasons.length === 0) {
      setCheckboxError(getErrorMessage('REASON_REQUIRED'));
      return;
    }
    setCheckboxError(''); // Clear error if validation passes

    await submitAction(); // Ensure this is awaited
  });

  // Render loading state if not authenticated
  if (!isAuthenticated) {
    return <div>Checking authentication...</div>;
  }

  // Render the form for user input
  return (
    <div className={`login-page initial-user-info ${isLoading ? 'loading' : ''}`}>
      <div className="login_form">
        <form onSubmit={handleSubmit}>
          <h2>友達さんの会員情報登録</h2>
          {error && <p className="error-message">{error}</p>}
          <div className="input_box">
            <label htmlFor="nationality">国籍 <span className="vietnamese-explain">(Quốc tịch)</span></label>
            <select
              id="nationality"
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
              required
            >
              <option value="">選択してください</option>
              <option value="Japan">日本</option>
              <option value="Vietnam">ベトナム</option>
              <option value="Other">その他</option>
            </select>
          </div>

          {/* Common fields */}
          <div className="name-fields">
            <div className="input_box">
              <label htmlFor="lastName">姓 <span className="vietnamese-explain">(Họ và tên đệm)</span></label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>

            <div className="input_box">
              <label htmlFor="firstName">名 <span className="vietnamese-explain">(Tên)</span></label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="name-fields">
            <div className="input_box">
              <label htmlFor="lastNameKana">姓（カタカナ）</label>
              <input
                type="text"
                id="lastNameKana"
                value={lastNameKana}
                onChange={(e) => setLastNameKana(e.target.value)}
                required
              />
            </div>

            <div className="input_box">
              <label htmlFor="firstNameKana">名（カタカナ）</label>
              <input
                type="text"
                id="firstNameKana"
                value={firstNameKana}
                onChange={(e) => setFirstNameKana(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="input_box">
            <label htmlFor="gender">性別 <span className="vietnamese-explain">(Giới tính)</span></label>
            <select
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">選択してください</option>
              <option value="male">男</option>
              <option value="female">女</option>
            </select>
          </div>

          <div className="input_box">
            <label htmlFor="birthYear">生年 <span className="vietnamese-explain">(Năm sinh)</span></label>
            <select
              id="birthYear"
              value={birthYear}
              onChange={(e) => setBirthYear(e.target.value)}
              required
            >
              <option value="">選択してください</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          {nationality === 'Japan' && (
            <>

              <div className="input_box">
                <label htmlFor="vietnameseSpeakingAbility">ベトナム語会話力</label>
                <select
                  id="vietnameseSpeakingAbility"
                  value={vietnameseSpeakingAbility}
                  onChange={(e) => setVietnameseSpeakingAbility(e.target.value)}
                  required
                >
                  <option value="">選択してください</option>
                  <option value="なし">なし</option>
                  <option value="初級">初級</option>
                  <option value="中級">中級</option>
                  <option value="中上級">中上級</option>
                  <option value="上級">上級</option>
                  <option value="熟練者">熟練者</option>
                </select>
              </div>

              <div className="input_box">
                <label htmlFor="vietnameseStudyPeriod">ベトナム語学習期間</label>
                <select
                  id="vietnameseStudyPeriod"
                  value={vietnameseStudyPeriod}
                  onChange={(e) => setVietnameseStudyPeriod(e.target.value)}
                  required
                >
                  <option value="">選択しください</option>
                  <option value="なし">なし</option>
                  <option value="<1年">&lt;1年</option>
                  <option value="1年〜3年">1年〜3年</option>
                  <option value="3年〜5年">3年〜5年</option>
                  <option value="5年〜10年">5年〜10年</option>
                  <option value=">10年">&gt;10年</option>
                </select>
              </div>

              <div className="input_box">
                <label htmlFor="vietnamLivingPeriod">ベトナム滞在期間</label>
                <select
                  id="vietnamLivingPeriod"
                  value={vietnamLivingPeriod}
                  onChange={(e) => setVietnamLivingPeriod(e.target.value)}
                  required
                >
                  <option value="">選択してください</option>
                  <option value="なし">なし</option>
                  <option value="<1年">&lt;1年</option>
                  <option value="1年〜3年">1年〜3年</option>
                  <option value="3年〜5年">3年〜5年</option>
                  <option value="5年〜10年">5年〜10年</option>
                  <option value=">10年">&gt;10年</option>
                </select>
              </div>

              <div className="input_box">
                <label>交流会に参加した理由（複数選択可）</label>
                <div className="checkbox-group">
                  {['ベトナム語会話力を練習したい', '異文化交流を楽しみたい', '気軽いにいろいろな人と話したい'].map((reason) => (
                    <div className="checkbox-item" key={reason}>
                      <input
                        type="checkbox"
                        id={`japanese-reason-${reason}`}
                        value={reason}
                        checked={japaneseReasons.includes(reason)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setJapaneseReasons([...japaneseReasons, reason]);
                          } else {
                            setJapaneseReasons(japaneseReasons.filter((r) => r !== reason));
                          }
                        }}
                      />
                      <label htmlFor={`japanese-reason-${reason}`}>{reason}</label>
                    </div>
                  ))}
                </div>
                {checkboxError && <p className="error-message">{checkboxError}</p>}
              </div>
            </>
          )}

          {(nationality === 'Vietnam' || nationality === 'Other') && (
            <>

              <div className="input_box">
                <label htmlFor="japaneseQualification">取得した日本語資格</label>
                <select
                  id="japaneseQualification"
                  value={japaneseQualification}
                  onChange={(e) => setJapaneseQualification(e.target.value)}
                  required
                >
                  <option value="">選択してください</option>
                  <option value="なし">なし</option>
                  <option value="N5">N5</option>
                  <option value="N4">N4</option>
                  <option value="N3">N3</option>
                  <option value="N2">N2</option>
                  <option value="N1">N1</option>
                </select>
              </div>

              <div className="input_box">
                <label htmlFor="japaneseSpeakingAbility">日本語会話力</label>
                <select
                  id="japaneseSpeakingAbility"
                  value={japaneseSpeakingAbility}
                  onChange={(e) => setJapaneseSpeakingAbility(e.target.value)}
                  required
                >
                  <option value="">選択してください</option>
                  <option value="なし">なし</option>
                  <option value="初級">初級</option>
                  <option value="中級">中級</option>
                  <option value="中上級">中上級</option>
                  <option value="上級">上級</option>
                  <option value="熟練者">熟練者</option>
                </select>
              </div>

              <div className="input_box">
                <label htmlFor="japaneseStudyPeriod">日本語学習期間</label>
                <select
                  id="japaneseStudyPeriod"
                  value={japaneseStudyPeriod}
                  onChange={(e) => setJapaneseStudyPeriod(e.target.value)}
                  required
                >
                  <option value="">選択してください</option>
                  <option value="なし">なし</option>
                  <option value="<1年">&lt;1年</option>
                  <option value="1年〜3年">1年〜3年</option>
                  <option value="3年〜5年">3年〜5年</option>
                  <option value="5年〜10年">5年〜10年</option>
                  <option value=">10年">&gt;10年</option>
                </select>
              </div>

              <div className="input_box">
                <label htmlFor="japanLivingPeriod">日本滞在期間</label>
                <select
                  id="japanLivingPeriod"
                  value={japanLivingPeriod}
                  onChange={(e) => setJapanLivingPeriod(e.target.value)}
                  required
                >
                  <option value="">選択してください</option>
                  <option value="なし">なし</option>
                  <option value="<1年">&lt;1年</option>
                  <option value="1年〜3年">1年〜3年</option>
                  <option value="3年〜5年">3年〜5年</option>
                  <option value="5年〜10年">5年〜10年</option>
                  <option value=">10年">&gt;10年</option>
                </select>
              </div>

              <div className="input_box">
                <label>交流会に参加した理由（複数選択可）</label>
                <div className="checkbox-group">
                  {['日本語会話力を練習したい', '異文化交流を楽しみたい', '気軽いにいろいろな人と話したい'].map((reason) => (
                    <div className="checkbox-item" key={reason}>
                      <input
                        type="checkbox"
                        id={`vietnamese-reason-${reason}`}
                        value={reason}
                        checked={vietnameseReasons.includes(reason)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setVietnameseReasons([...vietnameseReasons, reason]);
                          } else {
                            setVietnameseReasons(vietnameseReasons.filter((r) => r !== reason));
                          }
                        }}
                      />
                      <label htmlFor={`vietnamese-reason-${reason}`}>{reason}</label>
                    </div>
                  ))}
                </div>
                {checkboxError && <p className="error-message">{checkboxError}</p>}
              </div>
            </>
          )}

          <button type="submit" disabled={isLoading}>
            {isLoading ? '登録中...' : '登録する'}
          </button>
        </form>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <ClipLoader className="spinner" loading={isLoading} />
        </div>
      )}
    </div>
  );
}

export default InitialUserInfo;
