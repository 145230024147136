import React, { useState } from "react";
import "./Zoom.css";
import { ZoomMtg } from "@zoom/meetingsdk";

// Preload and prepare the Zoom Web SDK
ZoomMtg.setZoomJSLib('https://source.zoom.us/3.9.0/lib', '/av');
ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();

function Zoom() {
  const authEndpoint = "https://xzj7ai8ppl.execute-api.ap-northeast-1.amazonaws.com/devan/auth-zoom";
  const sdkKey = 'y7quhxLnRrSAjCsADDlLXg';
  const meetingNumber = "84355504154";
  const passWord = "";
  const role = 1;
  const userName = `virtual-user-${Math.floor(Math.random() * 100)+1}`;
  const userEmail = "nguyen.an.2019@gmail.com";
  const registrantToken = "";
  const zakToken = "";
  const leaveUrl = "http://localhost:3000";

  // // Function to get the signature for Zoom Meeting SDK
  const getSignature = async () => {
    try {
      const req = await fetch(authEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingNumber: meetingNumber,
          role: role,
        }),
      });
      const res = await req.json();
      const signature = res.signature;
      console.log("Signature: ", signature);
      startMeeting(signature);
    } catch (e) {
      console.error("Error fetching signature:", e);
    }
  };

  // Function to start the Zoom meeting
  const startMeeting = (signature) => {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: leaveUrl, 
      success: (success) => {
        ZoomMtg.join({
          sdkKey: sdkKey,
          signature: signature, // role in SDK signature needs to be 0
          meetingNumber: meetingNumber,
          userEmail: userEmail,
          passWord: passWord,
          userName: userName,
          success: (joinSuccess) => {
            console.log("The meeting is create successful: ", joinSuccess)
            // createBreakoutRoom();
            // switchclass();
            // console.log("Meeting started successfully.");
          },
          error: (error) => {
            console.log(error)
          }
        })
      },
      error: (error) => {
        console.log(error)
      }
    })
  };

  // Function to create two breakout rooms without participants
  const createTwoBreakoutRooms = () => {
    console.log("Jump into createTwoBreakoutRooms");
    const breakoutRooms = [
      { name: "Breakout Room 1" },
      { name: "Breakout Room 2" }
    ];

    ZoomMtg.createBreakoutRoom({
      data: 1,
      success: (success) => {
        console.log("Two breakout rooms created successfully", success);
      },
      error: (error) => {
        console.error("Error creating breakout rooms", error);
      }
    });
  };

  // Function to close breakout rooms
  const closeBreakoutRooms = () => {
    ZoomMtg.closeBreakoutRoom({
      success: (success) => {
        console.log("Breakout rooms closed successfully", success);
      },
      error: (error) => {
        console.error("Error closing breakout rooms", error);
      }
    });
  };

  return (
    <div>
      <h1>友達さん、交流会参加ユーザ管理システム</h1>
      <button onClick={getSignature}>Zoom開始</button>
      <button onClick={createTwoBreakoutRooms}>CreateBreakoutRooms</button>
      <button onClick={closeBreakoutRooms}>Close Breakout Rooms</button>
      <div id="zmmtg-root" style={{ display: "none" }}></div>
    </div>
  );
}

export default Zoom;