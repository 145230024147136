import React, { useEffect, useRef, useState } from "react";
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";
import "./Admin.css";

function Admin() {
  const meetingSDKElementRef = useRef(null);
  const [inMeeting, setInMeeting] = useState(false);
  const client = ZoomMtgEmbedded.createClient();

  // const authEndpoint = "https://zoom-meeting-sdk-auth-sample-mlar.onrender.com";
  const authEndpoint = "https://xzj7ai8ppl.execute-api.ap-northeast-1.amazonaws.com/devan/auth-zoom";

  const sdkKey = "y7quhxLnRrSAjCsADDlLXg";
  const meetingNumber = "84355504154";
  const role = 1; //admin
  const leaveUrl = "http://localhost:3000";

  const getSignature = async () => {
    try {
      console.log("Start to get signature");
      const req = await fetch(authEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingNumber,
          role,
        }),
      });
      console.log("Request:", req);
      const res = await req.json();
      const signature = res.signature;
      console.log("Successful of get signature");
      startMeeting(signature);
    } catch (error) {
      console.error("Error fetching signature:", error);
    }
  };

  const startMeeting = (signature) => {
    console.log("Client", client);
    client
      .init({
        zoomAppRoot: meetingSDKElementRef.current,
        language: "en-US",
      })
      .then((e) => {
        console.log("init success", e);
      })
      .catch((e) => {
        console.log("init error", e);
      });

    client
      .join({
        sdkKey: sdkKey,
        signature: signature,
        meetingNumber: meetingNumber,
        userName: `admin-261`, // randomizing userName here for each meeting join
        password: "", // empty password as per your original setup
        userEmail: "admin@tomodachisan.com",
      })
      .then((e) => {
        console.log("The meeting is create successful", e);
        fetchAttendees();
      })
      .catch((e) => {
        console.log("The meeting is fail to join", e);
      });
  };

  const switchclass = async () => {
    console.log("Jump into switchclass");

    // Assuming fetchAttendees returns a promise (like an API call), we await its resolution.
    try {
      const attendeeslist = await fetchAttendees();

      // Check the type of attendeeslist
      console.log("Type of attendeeslist:", typeof attendeeslist);

      // Assuming attendeeslist is an array, you can process it with forEach or map
      const userIdList = attendeeslist.map((element) => {
        return element.userId; // Return a list of attendees user
      });
      console.log("List of User IDs:", userIdList);

      // Remove the current administration user
      const current_admin = client.getCurrentUser();
      const index = userIdList.indexOf(current_admin.userId);
      // Check if the value exists in the array
      if (index !== -1) {
        // Remove the value using splice
        userIdList.splice(index, 1);
      }

      const matchedAttendeesList = matchingAttendees(userIdList);
      console.log("matchedAttendeesList", matchedAttendeesList);

      assignAttendeestoBreakOutRoom([], matchedAttendeesList);
      // console.log("completed move user to break room ",matchedAttendeesList);
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  };

  const fetchAttendees = () => {
    const user = client.getAttendeeslist();
    if (user) {
      console.log("Current User:", user);
      return user;
    } else {
      console.log("No current user data available.");
    }
  };

  const fetchBreakRoom = () => {
    const breakroomlist = client.getBreakoutRoomList();
    if (breakroomlist) {
      console.log("Current breakroomlist:", breakroomlist);
      return breakroomlist;
    } else {
      console.log("No current user data available.");
    }
  };

  // get userlist and roomlist is empty, export user_id and room_id for each user
  // matchingAttendees(users)
  // input: user = [user_id1, user_id2, user_id3, ...] for breakout user and unassign user
  function matchingAttendees(breakoutUsers, unassignAttendees) {
    console.log("breakoutUsers:", breakoutUsers);
    console.log("unassignAttendees:", unassignAttendees);
    const result = [];
    let filterUnAssignAttendees = [];

    // Assuming `breakoutUsers` and `unassignAttendees` are supposed to be arrays
    if (breakoutUsers.length > 0 || unassignAttendees.length>0) {
      filterUnAssignAttendees = unassignAttendees.filter(
        (item) => !breakoutUsers.includes(item)
      );
      // Now `filterUnAssignAttendees` contains attendees not in breakout rooms.
      console.log(filterUnAssignAttendees); // You can see the filtered results here
    } else {
      // Log an error or handle the case where arrays are not properly initialized
      console.log(
        "Either breakoutUsers or unassignAttendees is not an array or is empty."
      );
    }
    // Fetch break rooms
    const breakRoomList = fetchBreakRoom();
    // Filter only empty rooms to avoid altering the original breakRoomList unnecessarily
    const emptyRoomList = breakRoomList.filter(
      (room) => room.attendeeList.length === 0
    );

    // Early exit if no empty rooms are available
    if (emptyRoomList.length === 0) {
      console.error("No empty rooms available");
      return result;
    }

    // Combine both user lists
    const users = [...breakoutUsers, ...filterUnAssignAttendees];

    // Minimize the loop condition checks to handle all users that can be paired
    for (
      let i = 0, roomIndex = 0;
      i < users.length && roomIndex < emptyRoomList.length;
      i += 2, roomIndex++
    ) {
      // Take two users at a time
      let roomUsers = users.slice(i, i + 2);

      // Fetch the current room based on the room index
      let currentRoom = emptyRoomList[roomIndex];

      // Assign each user to the current room and determine their original group
      roomUsers.forEach((user) => {
        result.push({
          user_id: user,
          room_id: currentRoom.roomId,
          // Fix the condition to check presence properly and assign status
          status: breakoutUsers.includes(user) ? "breakout" : "unassign",
        });
      });
    }

    // Check if not all users could be paired or assigned due to room shortage
    if (Math.floor(users.length / 2) >= emptyRoomList.length) {
      console.error("Not enough empty rooms for all users");
    }

    console.log("Matching and pairing complete for each user pair", result);
    return result;
  }

  async function assignAttendeestoBreakOutRoom(attendeesRoomList) {
    for (const { user_id, room_id } of attendeesRoomList) {
      console.log(`Start to assign user ${user_id} to room ${room_id}`);
      try {
        await client.assignUserToRoom(user_id, room_id);
        console.log(`Success assigning user ${user_id} to room ${room_id}`);
      } catch (error) {
        console.error(
          `Failed to assigning user ${user_id} to room ${room_id}:`,
          error
        );
      }
    }
  }

  async function moveUsertoBreakOutRoom(attendeesRoomList) {
    for (const { user_id, room_id } of attendeesRoomList) {
      console.log(`Start to move user ${user_id} to room ${room_id}`);
      try {
        await client.moveUserToBreakoutRoom(user_id, room_id);
        console.log(`Success moving user ${user_id} to room ${room_id}`);
      } catch (error) {
        console.error(
          `Failed to move user ${user_id} to room ${room_id}:`,
          error
        );
      }
    }
  }

  function fetchUserListFromBreakoutRoom() {
    /**
     * Fetches the list of user IDs from all breakout rooms.
     *
     * Main Process:
     * - This function retrieves the list of breakout rooms and extracts user IDs from each room's attendee list.
     * - It returns a flat array containing the user IDs of all attendees in breakout rooms.
     *
     * Input:
     * - No input parameters.
     * - Internally calls `fetchBreakRoom()` to get the list of breakout rooms.
     *
     * Output:
     * - Returns an array of user IDs from all breakout rooms. [user_id1, user_id2, user_id3]
     * - If no breakout rooms or attendees are found, it returns an empty array. []
     *
     * Steps:
     * 1. Fetch the list of breakout rooms (`breakoutRoomList`).
     * 2. If no breakout rooms or they are empty, log a message and return an empty array.
     * 3. Use `flatMap` to iterate over each room and extract user IDs from the `attendeeList`.
     * 4. Log the list of user IDs.
     * 5. Return the array of user IDs.
     */
    const breakoutRoomList = fetchBreakRoom(); // Fetch the list of breakout rooms

    if (!breakoutRoomList || breakoutRoomList.length === 0) {
      console.log("There are no users in any breakout rooms.");
      return []; // Return an empty array if no rooms are found
    }

    // Use flatMap to iterate and flatten the list of userIds from each room's attendeeList [user_id1, user_id2, user_id3]
    const userInBreakoutRoomList = breakoutRoomList.flatMap((room) =>
      room.attendeeList && room.attendeeList.length > 0
        ? room.attendeeList.map((user) => user.userId)
        : []
    );

    console.log("User IDs in breakout rooms:", userInBreakoutRoomList);

    return userInBreakoutRoomList;
  }

  async function changeRoomForUserInBreakoutRoom() {
    const breakoutRoomUserList = fetchUserListFromBreakoutRoom();
    const unassignAttendees = fetchAttendees();

    const unassignUserId = unassignAttendees.map((element) => {
      return element.userId; // Return a list of attendees user
    });
    // Remove the current administration user
    const current_admin = client.getCurrentUser();
    const index = unassignUserId.indexOf(current_admin.userId);
    // Check if the value exists in the array
    if (index !== -1) {
      // Remove the value using splice
      unassignUserId.splice(index, 1);
    }

    const userPairResult = matchingAttendees(
      breakoutRoomUserList,
      unassignUserId
    );
    console.log("User pair result: ", userPairResult);

    const breakoutList = userPairResult.filter(
      (element) => element.status === "breakout"
    );
    const unassignList = userPairResult.filter(
      (element) => element.status === "unassign"
    );

    console.log("breakoutList: ", breakoutList);
    console.log("unassignList: ", unassignList);

    if (unassignList.length > 0) {
      await assignAttendeestoBreakOutRoom(unassignList);
    }

    if (breakoutList.length > 0) {
      await moveUsertoBreakOutRoom(breakoutList);
    }
  }

  return (
    <div className="admin-page">
      <h1>友達さん、交流会参加ユーザ管理システム</h1>
      <div className="button-container">
        <button onClick={getSignature}>Zoom開始</button>
        <button onClick={fetchAttendees}>参加者一覧</button>
        <button onClick={changeRoomForUserInBreakoutRoom}>切り替え</button>
        {/* <button onClick={changeRoomForUserInBreakoutRoom}>全て参加者</button> */}
        <div ref={meetingSDKElementRef} id="meetingSDKElement"></div>
      </div>
    </div>
  );
}

export default Admin;
