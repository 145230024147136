import React from "react";
import "./Homebody.css";
import avatar from "./avatar.svg";
import friends from "./friends.png";
import process_image from "./process.png";
import talk from "./talk.jpg";
import { useState, useEffect } from "react";
import Header from "../Header/Header"; // Import the Header component
import { fetchAuthSession } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Hub } from "@aws-amplify/core";
import { ClipLoader } from "react-spinners"; // Import ClipLoader
import { Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports';
// Configure Amplify with AWS settings
Amplify.configure(awsconfig);
const apiDomain = awsconfig.aws_cloud_logic_custom[0].endpoint;

const Homebody = () => {
  const [eventDate, setEventDate] = useState("");
  const [eventNumber, setEventNumber] = useState(1); // You can adjust this logic based on your event number system
  const [showPopup, setShowPopup] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // Initialize the navigate function
  const [timeLeft, setTimeLeft] = useState("");

  const checkLoginStatus = async () => {
    setIsLoading(true); // Set loading to true when starting the fetch
    try {
      const user = await fetchAuthSession();
      // console.log("ユーザー情報:", user);
      if (user) {
        setLoginStatus(true);
        setShowPopup(false);

        // Call API to get user info with email
        const response = await fetch(
          `${apiDomain}/userinfo`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.tokens.idToken}`, // Ensure the token is correct
            },
          }
        );
        const userInfo = await response.json();
        // console.log("User Info from API:", userInfo);

        // Check if user_exists is true or false
        // console.log("userInfo.user.user_exists:", userInfo.user_exists);
        if (userInfo.user_exists === false) {
          navigate("/initial-user-info"); // Use navigate to redirect
        }
      } else {
        setLoginStatus(false);
      }
    } catch (error) {
      setLoginStatus(false);
    } finally {
      setIsLoading(false); // Set loading to false after fetch completes
    }
  };

  const calculateTimeLeft = () => {
    const now = new Date();
    const nextSunday = new Date();
    nextSunday.setDate(now.getDate() + ((7 - now.getDay()) % 7));

    // Get the current time in JST as a Date object
    const jstDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Tokyo" }));
    // Get the user's current time as a Date object
    const userDate = new Date();

    // Calculate the time difference in hours
    const timeDifference = Math.round((jstDate - userDate) / (1000 * 60 * 60));

    const target_hour = 21 - timeDifference;
    nextSunday.setHours(target_hour, 0, 0, 0); // 21:00 JST

    const difference = nextSunday - now;
    
    if (difference < 0) return false

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return `${days}日 ${hours}時 ${minutes}分 ${seconds}秒`;
  };

  useEffect(() => {
    // checkLoginStatus();

    const now = new Date();
    const nextSunday = new Date();

    // Calculate the next Sunday, now.getDay() return a interger value from 0 (Sunday) to 6 (Saturday)
    const daysUntilSunday = (7 - now.getDay()) % 7;
    nextSunday.setDate(now.getDate() + daysUntilSunday);
    nextSunday.setHours(0, 0, 0, 0);

    const formattedDate = `${
      nextSunday.getMonth() + 1
    }月${nextSunday.getDate()}日(日)`;
    setEventDate(formattedDate);

    // Base date and number
    const baseDate = new Date("2024-08-26 01:00:00"); // Adjust base date as needed
    const baseEventNumber = 24;

    // Calculate the number of weeks since the base date
    const msPerWeek = 7 * 24 * 60 * 60 * 1000;
    const weeksPassed = Math.floor((now - baseDate) / msPerWeek);

    // Update the event number
    setEventNumber(baseEventNumber + weeksPassed + 1);

    //left time caculating
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    const authListener = ({ payload }) => {
      // console.log("Auth event:", payload.event); // Log all auth events
      // console.log("Payload:", payload); // Log all auth events
      switch (payload.event) {
        case "signedIn":
          console.log("User has been signed in successfully.");
          checkLoginStatus();
          // Further process the data as needed
          break;
        case "signedOut":
          console.log("User has been signed out successfully.");
          break;
        case "tokenRefresh":
          console.log("Auth tokens have been refreshed.");
          break;
        case "tokenRefresh_failure":
          console.log("Failure while refreshing auth tokens.");
          break;
        case "signInWithRedirect":
          console.log("signInWithRedirect API has successfully been resolved.");
          break;
        case "signInWithRedirect_failure":
          console.log(
            "Failure while trying to resolve signInWithRedirect API."
          );
          break;
        case "customOAuthState":
          console.log("Custom state returned from Cognito Hosted UI");
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", authListener);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const redirectToUrl = () => {
    window.location.href =
      "https://us06web.zoom.us/j/84355504154?pwd=V6Je0K05mvHmMjquWbSCbbVNPAU3Ee.1";
  };

  return (
    <main>
      <Header className="page1-section" />
      {isLoading ? ( // Conditionally render the loader
        <div className="loading-overlay">
          <ClipLoader className="spinner" loading={isLoading} />
        </div>
      ) : (
        <section className="page1-section">
          <section className="header-section">
            <h1 className="title">
              毎週日曜日ベトナム語日本語オンライン交流会
            </h1>
            <p className="title">（{eventNumber}回目）</p>
          </section>
          <figure className="image-container">
            <img src={avatar} alt="human" />
          </figure>
          <section className="zoom-url">
            <section className="date-time-container">
              <p className="date-time">{eventDate}</p>
              <div className="timezone">
                <p>21:00~22:30 (日本)</p>
                <p>19:00~20:30 (ベトナム)</p>
              </div>
            </section>
            <section className="participant-timeleft-container">
              <button className="button button-participant" onClick={redirectToUrl}>
                参加
              </button>
              <div className="time-left">
                {timeLeft && <p>残り：{timeLeft}</p>}
              </div>
              
            </section>

          </section>
        </section>
      )}

      <br></br>

      <section className="page2-section">
        <article className="content-container1">
          <div className="paticipant">
            <h2>対象</h2>
            <ul>
              <li>日本とベトナムの生活や文化に興味がある方</li>
              <li>新しい友達を作りたい方</li>
              <li>日本語で会話したいベトナムの方</li>
              <li>ベトナム語で会話したい日本人の方</li>
            </ul>
          </div>
          <figure>
            <img src={friends} alt="対象" />
          </figure>
        </article>

        <article className="content-container2">
          <figure className="icon">
            <img src={process_image} alt="参加手順" />
          </figure>
          {/* <h4 className='icon'>ホストがメインルームから各ブレイクアウトルームに移動させます。</h4> */}
          <div className="process">
            <h2>参加手順</h2>
            <ol>
              <li>
                <strong>「参加」</strong>ボタンを押してZoomに参加してください
              </li>
              <li>Zoomに参加後、最初にメインルームに入室します</li>
              <li>
                ホストが各メンバーをメインルームからそれぞれのブレイクアウトルームに移動させます
              </li>
              <li>
                各ブレイクアウトルームは30分ごとに切り替わります
                <ul className="session-list">
                  <li>第1セッション: 21:00〜21:30</li>
                  <li>第2セッション: 21:30〜22:00</li>
                  <li>第3セッション: 22:00〜22:30</li>
                </ul>
              </li>
            </ol>
          </div>
        </article>

        <article className="content-container1">
          <div className="paticipant">
            <h2>内容</h2>
            <ul>
              <li>話題は自由です</li>
              <li>参加費は無料です</li>
              <li>
                日本人とベトナム人がペアを組んで、優先的にクラスを設置します
              </li>
              <li>カメラをオンにしてご参加ください</li>
            </ul>
          </div>
          <figure>
            <img src={talk} alt="対象" />
          </figure>
        </article>
      </section>

      <footer className="footer">
        <p>
          <a
            href="https://www.facebook.com/groups/tomodachisan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook"></i> グループ参加
          </a>
        </p>
      </footer>

      <section>
        {/* Popup for Facebook Group Introduction */}
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <h3>
                「日本語オンライン交流会（友達さん）」グループに参加しましよう！
              </h3>
              <div className="fb-group-container">
                <p>
                  <a
                    href="https://www.facebook.com/groups/tomodachisan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook"></i> グループ参加
                  </a>
                </p>
              </div>
              <button onClick={handleClosePopup}>閉じる</button>
            </div>
          </div>
        )}
      </section>
    </main>
  );
};

export default Homebody;

