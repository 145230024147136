import React, { useState, useEffect } from "react";
import { Amplify } from 'aws-amplify';
import { confirmSignUp, resendSignUpCode, signIn } from '@aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import "./Login.css";
import awsconfig from '../../aws-exports';
import useSubmitButton from '../../hooks/useSubmitButton';
import { getErrorMessage } from '../../utils/errorMessages';

Amplify.configure(awsconfig);

function ConfirmSignup() {
  const [confirmationCode, setConfirmationCode] = useState("");
  const [countdown, setCountdown] = useState(60); // Start with 2 minutes
  const [confirmationMessage, setConfirmationMessage] = useState("確認コードをメールで送信しました。以下に入力してください。");
  const [showNoEmailMessage, setShowNoEmailMessage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { email, password } = location.state || {};

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  useEffect(() => {
    if (!email || !password) {
      console.error('Email or password is missing');
      navigate('/signup'); // Redirect back to signup if email or password is missing
    }
  }, [email, password, navigate]);

  const submitAction = async () => {
    console.log('Starting submitAction');
    
    if (!email || !password) {
      console.error('Missing email or password');
      throw new Error(getErrorMessage('INVALID_CREDENTIALS'));
    }

    try {
      console.log('Attempting to confirm signup');
      await confirmSignUp({
        username: email,
        confirmationCode: confirmationCode
      });
      console.log('サインアップ確認成功');
      
      // Automatically sign in the user after successful confirmation
      try {
        console.log('Attempting to sign in');
        const signInResponse = await signIn({
          username: email,
          password: password
        });
        // console.log('サインイン成功', signInResponse);
        navigate('/initial-user-info', { state: { email, password } });
      } catch (signInError) {
        console.error('サインインエラー', signInError);
        console.error('Sign-in error details:', signInError.message, signInError.code);
        throw new Error(getErrorMessage(signInError));
      }
    } catch (error) {
      console.error('サインアップ確認エラー', error);
      console.error('Confirmation error details:', error.message, error.code);
      throw new Error(getErrorMessage(error));
    }
  };

  const { isLoading, error, handleSubmit } = useSubmitButton(submitAction);

  const handleResendCode = async () => {
    if (!email || countdown > 0) {
      return;
    }

    try {
      await resendSignUpCode({ username: email });
      setConfirmationMessage('確認コードが再送信されました。メールをご確認ください。');
      setCountdown(60); // Reset to 2 minutes
      setShowNoEmailMessage(false);
    } catch (error) {
      console.error('確認コードの再送信エラー', error);
      setConfirmationMessage(getErrorMessage(error));
    }
  };

  const handleNoEmail = () => {
    setShowNoEmailMessage(true);
  };

  return (
    <div className={`login-page ${isLoading ? 'loading' : ''}`}>
      <div className="login_form">
        <form onSubmit={handleSubmit}>
          <h2>アカウント確認</h2>
          <p className="confirm-signup-explain">
            {confirmationMessage}
          </p>
          <div className="input_box">
            <label htmlFor="confirmationCode">確認コード</label>
            <input
              type="text"
              id="confirmationCode"
              placeholder="確認コードを入力してください"
              required
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              autoComplete="off"
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" disabled={isLoading}>
            {isLoading ? '確認中...' : 'コード確認する'}
          </button>
          <p className="resend-code">
            コードが届きませんでしたか？ 
            {countdown > 0 ? (
              <span className="resend-countdown">{countdown}秒後に再送信可能</span>
            ) : (
              <span onClick={handleResendCode} className="resend-link">コードを再送信する</span>
            )}
          </p>
          {showNoEmailMessage && (
            <div className="no-email-message">
              <p>メールが届かない場合は以下をご確認ください：</p>
              <ul>
                <li>迷惑メールフォルダをチェックしてください。</li>
                <li>入力したメールアドレスが正しいことを確認してください。</li>
                <li>しばらく待ってから、再度コードを送信してください。</li>
              </ul>
            </div>
          )}
        </form>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <ClipLoader className="spinner" loading={isLoading} />
        </div>
      )}
    </div>
  );
}

export default ConfirmSignup;
