import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {  signOut } from '@aws-amplify/auth';

const InactivityProvider = ({ children }) => {
  let inactivityTimeout;

  // Set inactivity timeout in milliseconds (e.g., 1 hour = 3600000 ms)
    const INACTIVITY_TIME = 21600000; // Set time out as 6hours

  // Function to sign out the user
  const signOutUser = async () => {
    try {
      await signOut();
      console.log("User is signout automatically")
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Function to reset the inactivity timer
  const resetInactivityTimer = () => {
    if (inactivityTimeout) {
      clearTimeout(inactivityTimeout);
    }
    // Start a new timer
    inactivityTimeout = setTimeout(signOutUser, INACTIVITY_TIME);
  };

  useEffect(() => {
    // Reset the timer on page load
    resetInactivityTimer();

    // Add event listeners to detect user activity
    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);
    window.addEventListener("click", resetInactivityTimer);

    // Clean up event listeners and timeout on component unmount
    return () => {
      clearTimeout(inactivityTimeout);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
      window.removeEventListener("click", resetInactivityTimer);
    };
  }, []);

  return <>{children}</>; // Render the wrapped components
};

export default InactivityProvider;