import { useState } from 'react';

const useSubmitButton = (submitAction) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await submitAction();
    } catch (error) {
      console.error('Error during submission:', error);
      setError(error.message || '操作中にエラーが発生しました。もう一度お試しください。');
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, setError, handleSubmit };
};

export default useSubmitButton;