import React, { useState, useEffect } from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, signOut } from '@aws-amplify/auth'; // Import necessary functions
import { FaUserCircle } from 'react-icons/fa'; // Import a user circle icon

const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserStatus = async () => {
            try {
                const user = await getCurrentUser();
                setIsLoggedIn(!!user);
            } catch {
                setIsLoggedIn(false);
            }
        };

        checkUserStatus();
    }, []);

    const handleSignup = () => {
        navigate('/signup');
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const handleLogout = async () => {
        try {
            await signOut();
            setIsLoggedIn(false); // Update the state to reflect the user is logged out
            navigate('/'); // Redirect to home or login page after logout
        } catch (error) {
            console.error('Logout error', error);
        }
    };

    return (
        <header className="header">
            <div className="logo-container" onClick={() => navigate('/')}>
                <div className="logo">友達さん</div>
                <div className="logo-explain">tomodachisan</div>
            </div>
            <div className="header-buttons">
                {isLoggedIn ? (
                    <div className="user-menu">
                        <FaUserCircle size={40} className="user-icon" />
                        <div className="dropdown-content">
                            <button onClick={handleLogout}>ログアウト</button>
                            {/* <button onClick={() => navigate('/profile')}>ユーザー情報</button> */}
                        </div>
                    </div>
                ) : (
                    <div className='login-signup'>
                        <button onClick={handleSignup}>新規登録</button>
                        <button onClick={handleLogin}>ログイン</button>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
